import { api } from "../conexoes/api";
import decryptJWT from "../utils/decryptJWT";

export const TOKEN_KEY = "@veye-Token";

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = (autenticacao) => {
  localStorage.setItem(TOKEN_KEY, autenticacao.token);
  localStorage.setItem("recebeuMensagemLogin", false);
};

export const logout = () => {
  const localTema = localStorage.getItem("tema");
  const flChat = localStorage.getItem("fl_chat");
  localStorage.clear();
  localStorage.setItem("tema", localTema);
  localStorage.setItem("fl_chat", flChat);
};

export async function isAuthenticated() {
  try {
    await api.post("/m1/servicos/dados/validacao_token", {
      token: localStorage.getItem(TOKEN_KEY),
    });
  } catch (error) {
    await api
      .post("/m1/servicos/dados/logout", {
        id: decryptJWT("id"),
      })
      .then(() => {})
      .catch((error) => {
        if (decryptJWT("@veye-Token")) {
          window.location.reload();
        }

        logout();
      });
  }
}
