//IMPORTAÇÕES
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import { EstilosGlobais } from "./css/global";
import { claroTema, escuroTema } from "./css/temas";
import { isPortal } from "./utils/nivelCliente.js";
import Routers from "./routers/index.jsx";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function App() {
  // armazena o valor do tema (claro ou algarClaro) ou (escuro ou algarEscuro)
  // que será usado para definir com qual tema será iniciado o sistema
  // OBS.: Os temas algarClaro e algarEscuro, parecem estar em desuso, mas foi mantido
  // como item verificador para o caso de por alguma ocasião, vir com este valor.
  const localTema = localStorage.getItem("tema");
  let temaAtual = localTema || "claro";
  const isEscuro = temaAtual === "escuro";
  // Função que retorna os valores de coresTema, de acordo com o valor de @localTema
  const corTema = () => {
    if (isEscuro) return escuroTema;
    else return claroTema;
  };

  const [coresTema, setCoresTema] = useState(corTema());
  const [modoTema, setModoTema] = useState(false);
  const [buscarTema, setBuscarTema] = useState(false);

  useEffect(() => {
    setCoresTema(corTema());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buscarTema, modoTema]);

  const originalSetItem = localStorage.setItem;

  localStorage.setItem = function () {
    const event = new Event("itemInserted");
    document.dispatchEvent(event);
    originalSetItem.apply(this, arguments);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={coresTema}>
        <EstilosGlobais />
        <Routers
          buscarTema={buscarTema}
          coresTema={coresTema}
          setBuscarTema={setBuscarTema}
          modoTema={modoTema}
          setModoTema={setModoTema}
        />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

function mapStateToProps(state) {
  return {
    systemThemeState: state.systemControlStates.systemTheme,
  };
}

export default connect(mapStateToProps)(React.memo(App));
